import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";
import { ToastContainer, Slide, toast } from "react-toastify";

import Home from "./Home";
import Login from "./Login";
import Loading from "Components/Shared/Loading";
import NewAppVersionModal from "../Components/App/NewAppVersionModal";
import Error from "./Error";
import ChatBot from "../Components/ChatBot/ChatBot";

import { hotjar } from "Utils/utils";

import "./styles/App.css";

import { IUser, USER_ACCOUNT_TYPE_VALUES } from "Models/UserModels";

import useDataApi from "Hooks/fetchHook";
import { useFetchBrands } from "Hooks/queryHooks/useFetchBrands";
import { useFetchShippingDetails } from "Hooks/queryHooks/useFetchShippingDetails";
import { useFetchShippingMethods } from "Hooks/queryHooks/useFetchShippingMethods";
import { useFetchLabelTemplates } from "Hooks/queryHooks/useFetchLabelTemplates";
import { useFetchAccExec } from "Hooks/queryHooks/useFetchAccExec";

import { queryClient } from "react-query/queryClient";
import AppDispatch from "Dispatches/AppDispatch";

const App: React.FC<{}> = () => {
	const [showLoadingWhileInStartup, setShowLoadingWhileInStartup] =
		useState(true);
	const [error, setError] = useState(undefined) as any;
	const [user, setUser] = useState<IUser | undefined>(undefined);
	const [newVersion, setNewVersion] = useState<boolean>(false);

	const useGetCurrentUser = useDataApi();
	const useLogout = useDataApi();

	const navigate = useNavigate();
	const location = useLocation();

	const toastId = React.useRef<undefined | string | number>(undefined);

	useEffect(() => {
		if (import.meta.env.VITE_APP_ENV === "production") {
			hotjar(2389110, 6);
		}
		if (import.meta.env.VITE_APP_ENV !== "production") {
			document.body.style.backgroundImage = `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='60px' width='225px'><text x='0' y='50' fill='LightSkyBlue' opacity='0.2' font-size='40'>${
				import.meta.env.VITE_APP_ENV
			}</text></svg>"`;
		}
	}, []);

	useEffect(() => {
		if (useGetCurrentUser.data) {
			const { message: user } = useGetCurrentUser.data;
			if (user) {
				setUser(user);
				setShowLoadingWhileInStartup(false);
				if (location.pathname === "/login") {
					navigate("/", { replace: true });
				}
			}
		}
	}, [useGetCurrentUser.data]); // eslint-disable-line react-hooks/exhaustive-deps

	let account_type_id: USER_ACCOUNT_TYPE_VALUES | undefined = undefined;

	if (user) {
		account_type_id = user.account_type_id;
	}
	const { isLoadingBrands } = useFetchBrands(account_type_id, setError);
	const { isLoadingShippingDetails } = useFetchShippingDetails(
		account_type_id,
		setError
	);

	const { isLoadingShippingMethods } = useFetchShippingMethods(
		account_type_id,
		setError
	);
	const { isLoadingLabelTemplates } = useFetchLabelTemplates(
		account_type_id,
		setError
	);

	const { isLoadingAccountExecutives } = useFetchAccExec(
		account_type_id,
		setError
	);

	useEffect(() => {
		const { data } = useLogout;
		if (data && data.message) {
			setUser(undefined);
			setShowLoadingWhileInStartup(false);
			queryClient.clear();
			navigate("/login"); // after logout to redirect to "/login"
		}
	}, [useLogout.data]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		useGetCurrentUser.doFetch("/users/current");
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setShowLoadingWhileInStartup(false);
	}, [useGetCurrentUser.error]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const { error } = useLogout;
		if (error) {
			toast.error(`Logout failed. Please try again later. Error: ${error}`);
		}
		setShowLoadingWhileInStartup(false);
	}, [useLogout.error]); // eslint-disable-line react-hooks/exhaustive-deps

	const onLogout = () => {
		useLogout.doFetch("/logout");
	};

	const [isOnline, setIsOnline] = useState(navigator.onLine);

	useEffect(() => {
		// Update network status
		const handleStatusChange = () => {
			setIsOnline(navigator.onLine);
		};

		if (!isOnline) {
			toastId.current = toast.warn(
				`You are offline. \nPlease check your internet connection or try again later.`,
				{
					autoClose: false,
					closeButton: false,
					closeOnClick: false,
					draggable: false,
				}
			);
		} else {
			toast.dismiss(toastId.current);
		}
		// Listen to the online status
		window.addEventListener("online", handleStatusChange);
		// Listen to the offline status
		window.addEventListener("offline", handleStatusChange);
		// Specify how to clean up after this effect for performance improvment
		return () => {
			window.removeEventListener("online", handleStatusChange);
			window.removeEventListener("offline", handleStatusChange);
		};
	}, [isOnline]);

	let reactQueryFetch = [
		isLoadingBrands,
		isLoadingShippingDetails,
		isLoadingShippingMethods,
		isLoadingLabelTemplates,
		isLoadingAccountExecutives,
	];

	const isLoadingReactQueryFetch = reactQueryFetch.some(
		(isLoading) => isLoading
	);

	const reactQueryFetchFinished = reactQueryFetch.every(
		(isLoading) => !isLoading
	);

	if (error)
		return (
			<main className="mainContent">
				<Error error={error} setError={setError} />
				<ToastContainer
					transition={Slide}
					position="bottom-right"
					theme="colored"
				/>
			</main>
		);

	if (newVersion) return <NewAppVersionModal />;

	return (
		<main className="mainContent">
			<AppDispatch.Provider
				value={{
					setUser,
					setNewVersion,
				}}
			>
				<div className="content">
					<Loading
						show={
							useGetCurrentUser.isLoading ||
							useLogout.isLoading ||
							isLoadingReactQueryFetch
						}
						text={"Loading..."}
						imgClass="block-center"
						divClass="col-sm-12"
					/>

					{!useLogout.isLoading &&
						!useGetCurrentUser.isLoading &&
						reactQueryFetchFinished &&
						user && (
							<Home user={user} onLogout={onLogout} setError={setError} />
						)}

					{!showLoadingWhileInStartup &&
						!useGetCurrentUser.isLoading &&
						!useLogout.isLoading &&
						!user && <Login setUser={setUser} />}
				</div>

				<ToastContainer
					transition={Slide}
					position="bottom-right"
					theme="colored"
				/>

				{user && <ChatBot userId={user.id} userName={user.name} />}
			</AppDispatch.Provider>
		</main>
	);
};

export default App;
